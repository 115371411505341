@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "primeng/resources/primeng.css";
@import "primeng/resources/themes/bootstrap4-light-blue/theme.css";
@import 'ngx-sharebuttons/themes/default';

/* You can add global styles to this file, and also import other style files */

:root {
  --bs-gutter-x: 0;
  /* Set the horizontal gutter to 0 */
  --base-font-size: 100%;  /* Start with 100% as the default size (100%) */
}

html {
  font-size: var(--base-font-size);
  transition: font-size 0.3s ease;  /* Smooth transition for font size changes */
}
body {
  font-size: 1rem; /* 1rem = 100% of the root font size */
}
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@font-face {
  font-family: 'Somar Sans';
  font-weight: 400; // Regular
  font-style: normal;
  src: url('./assets/fonts/SomarSans-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Somar Sans';
  font-weight: 600; // Semi-Bold
  font-style: normal;
  src: url('./assets/fonts/SomarSans-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'Somar Sans';
  font-weight: 500; // Medium
  font-style: normal;
  src: url('./assets/fonts/SomarSans-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Somar Sans';
  font-weight: 700; // Bold
  font-style: normal;
  src: url('./assets/fonts/SomarSans-Bold.otf') format('opentype');
}

.carousel-item {
  margin-left: auto !important;
}

[dir="ltr"] .arrow-left,
[dir="ltr"] .arrow-more,
[dir="ltr"] .arrow-right {
  transform: rotate(180deg);
}

[dir="rtl"] .arrow-left,
[dir="rtl"] .arrow-more,
[dir="rtl"] .arrow-right {
  transform: rotate(0deg);
}
.show-fade {
  opacity: 1!important;
  transition: opacity 0.5s ease-in-out!important;
}

.hide-fade {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.list-item-hide{
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}
.list-item-visible {
    opacity: 1 !important;
    transform: translateY(0px) !important;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }

.dropdown{
  direction: rtl !important;
}

.highlight {
  background-color: rgba(128, 128, 128, 0.466);
  font-weight: bold;
}


.error
{
  margin: 3px;
  color: darkred;
}
.filter-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 28px; /* Spacing between the elements */
  padding: 24px;
  width: 100%;
  border-radius: 16px;
  background: var(--Support-primary-Brown-25, #FAF9F7);
  flex-wrap: wrap; /* Ensure responsiveness in case of small screens */
  .filter-input-sec {
      position: relative;
      padding: 0;
    }
    
    .filter-input-sec .icon-search-input {
      position: absolute;
      top: 50%;
      margin-inline-start: 10px;
      transform: translateY(-50%);
      font-size: 1.125rem;
      width: 16px;
      height: 16px;
      color: green; /* Icon color */
      content: url('/assets/images/search_icon_green.svg');
    }
    
    .filter-input-sec .input.filter {
      width: 100%;
      padding-inline-start: 30px; /* Space for the icon */
      padding-inline-end: 10px; /* Padding on the right side */
      padding-top: 16px;
      padding-bottom: 16px;
      border-radius: 64px;
      border: 1px solid #9EBBB1;
      background: rgba(255, 255, 255, 0.20);
      background-blend-mode: multiply;
      color: #0C553C;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }
    .filter-input-sec .input.filter::placeholder{
      color: var(--Primary-300, #6D998A);
    }
    .dropdown {
      padding: 12px 16px;
      border-radius: 64px;
      color: #6D998A;
      font-size: 1rem;
      font-family: 'Somar Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      outline: none;
      border: none;
      line-height: 150%; /* 24px */ 
      background: url('/assets/images/arrow_drop_down_green.svg') no-repeat right #E7EEEC;
      -webkit-appearance: none;
      background-position-x: 7%;
    }
}      
.search-btn {
  padding: 16px 32px;
  border-radius: 64px;
  background: var(--Primary-500, #0C553C);
  color: #E7EEEC;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  border: none;
  line-height: 150%; /* 24px */    cursor: pointer;
}

.pagination-sec{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10 0px;
  .more{
    padding: 7px 140px;
    text-align: center;
    color: var(--Primary-25, #080a0a);
    border-radius: 64px;
    border: 1px solid  var(--Primary-25, #080a0a);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    transition: all 0.3s ease-in-out;
    &:hover{
      background: var(--Primary-500, #0C553C);
      color: var(--Primary-25, #E7EEEC);
      padding: 7px 150px;
    }
  }
}

.btn.more-news{
  display: flex;
  padding: 12px 28px;
  align-items: center;
  gap: 0px;
  border-radius: 64px;
  border: none !important;
  background: var(--Primary-500, #0C553C)!important;
  color: var(--Primary-25, #E7EEEC)!important;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 15.6px */
  transition: all 0.3s ease-in-out;
  .arrow-more{
      display: none;
      opacity: 0;
      width: 16px;
      height: 16px;
      content: url('/assets/images/arrow_left_white.svg');
      animation: fadeIn 0.3s linear;
  }
  &:hover{
      gap: 7px;
      .arrow-more{
          display: inline-flex;
          opacity: 1;
      }
  }
}
